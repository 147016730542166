import React from 'react';
import { connect } from 'react-redux';
import PersonalInfoCard from './PersonalInfoCard';
import { Container, CardContainer } from '../components';
import {
  changeProfileForm,
  updateProfile,
} from '../../shell/redux/actions/user';

import {
  showSuccessPopupNotification,
  showErrorPopupNotification,
} from 'src/products/shell/Notifications';

class PersonalInfo extends React.Component {
  state = {};

  onChange = (value, name) => {
    const { changeProfileForm } = this.props;

    changeProfileForm({ [name]: value });
  };

  onChangeRequest = (fieldName) => {
    this.setState({
      isDialogOpen: true,
      fieldToChage: fieldName,
    });
  };

  onSave = () => {
    this.saveData();
  };

  onCancel = () => {
    // reload user profile
  };

  async saveData() {
    const { updateProfile } = this.props;

    try {
      await updateProfile();
      showSuccessPopupNotification('Your profile was successfully updated.');
    } catch (e) {
      showErrorPopupNotification('Error! ' + e.message);
    }
  }

  render() {
    const { profileForm } = this.props;

    return (
      <Container>
        <CardContainer>
          <PersonalInfoCard
            data={profileForm.value}
            onChange={this.onChange}
            onSave={this.onSave}
            onCancel={this.onCancel}
          />
        </CardContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user: { profileForm, profile },
  } = state;
  return { profileForm, profile };
};

const mapDispatchToProps = {
  changeProfileForm,
  updateProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
