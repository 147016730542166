import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  padding-bottom: 5%;
`;

export const CardContainer = styled.div`
  margin-bottom: 15px;
  position: relative;
`;
