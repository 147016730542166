import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Person from '@material-ui/icons/Person';
import Business from '@material-ui/icons/Business';
import Mail from '@material-ui/icons/Mail';

const styles = {
  smallAvatar: {
    margin: '0 20px 0 0',
    width: 30,
    height: 30,
  },
  card: {
    width: 600,
  },
  listItem: {
    paddingLeft: 0,
    paddingTop: 8,
    paddingBottom: 8,
  },
  listIcon: {
    marginRight: 0,
  },
  cardContent: {
    padding: 30,
    '&:last-child': {
      paddingBottom: 30,
    },
  },
  textFieldShort: {
    marginRight: 30,
    width: 137,
  },
  textFieldWide: {
    width: 250,
  },
};

const spaceBelow = { marginBottom: 25 };

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -5px;
`;

class PersonalInfoCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
    };
  }

  onEdit = () => {
    this.setState({ edit: true });
  };

  onSaveClick = () => {
    const { onSave } = this.props;

    onSave();
    this.setState({ edit: false, phoneErrorMessage: '' });
  };

  onCancelClick = () => {
    const { onCancel } = this.props;

    onCancel();
    this.setState({ edit: false });
  };

  render() {
    const { classes } = this.props;
    const { edit } = this.state;

    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography style={spaceBelow} variant='h6'>
            Personal information
          </Typography>

          <HorizontalContainer style={spaceBelow}>
            {!edit && this.renderInfoPreview()}
            {edit && this.renderInfoEdit()}
          </HorizontalContainer>
          {!edit && this.renderActionsPreview()}
          {edit && this.renderActionsEdit()}
        </CardContent>
      </Card>
    );
  }

  renderInfoPreview() {
    const { data, classes } = this.props;

    return (
      <List>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listIcon}>
            <Person />
          </ListItemIcon>
          <ListItemText
            primary={`${data.firstName || ''} ${data.lastName || ''}`}
          />
        </ListItem>
        {data.email && (
          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.listIcon}>
              <Mail />
            </ListItemIcon>
            <ListItemText primary={data.email} />
          </ListItem>
        )}
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.listIcon}>
            <Business />
          </ListItemIcon>
          <ListItemText primary={data.organizationName} />
        </ListItem>
      </List>
    );
  }

  renderInfoEdit() {
    const { data, onChange, classes } = this.props;

    return (
      <List>
        <ListItem>
          <TextField
            id='firstName'
            label='Name'
            value={data.firstName}
            className={classes.textFieldShort}
            onChange={(e) => onChange(e.target.value, 'firstName')}
          />
          <TextField
            id='lastName'
            label='Last Name'
            value={data.lastName}
            className={classes.textFieldShort}
            onChange={(e) => onChange(e.target.value, 'lastName')}
          />
        </ListItem>
      </List>
    );
  }

  renderActionsPreview() {
    return (
      <HorizontalContainer
        style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}
      >
        <Button
          style={{
            position: 'absolute',
            bottom: 30,
            right: 30,
            border: '1px solid #ccc',
          }}
          color='primary'
          size='small'
          onClick={this.onEdit}
        >
          Edit
        </Button>
      </HorizontalContainer>
    );
  }

  renderActionsEdit() {
    return (
      <div>
        <HorizontalContainer style={{ justifyContent: 'flex-end' }}>
          <Button
            color='primary'
            style={{ marginRight: 20 }}
            size='small'
            onClick={this.onCancelClick}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            variant='contained'
            size='small'
            onClick={this.onSaveClick}
          >
            {' '}
            Save
          </Button>
        </HorizontalContainer>
      </div>
    );
  }
}

PersonalInfoCard.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    organizationName: PropTypes.string,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withStyles(styles)(PersonalInfoCard);
