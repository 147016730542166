import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import blueGrey from '@material-ui/core/colors/blueGrey';
import grey from '@material-ui/core/colors/grey';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

const ProfileContainer = styled.div`
  background-color: #eeeeee;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
`;

const ProfileContent = styled.div`
  width: 100%;
  background-color: #eeeeee;
  padding-top: 5%;
`;

const styles = () => ({
  listItem: {
    padding: '16px 32px',
    '& $itemIcon': {
      color: blueGrey[300],
    },
    '& $text': {
      color: blueGrey[600],
    },
    '&:focus': {
      outline: 'none',
      textDecoration: 'none',
    },
    '&:hover': {
      fontWeight: 500,
      background: grey[100],
      textDecoration: 'none',
      '& $itemIcon': {
        color: grey[600],
      },
      '& $text': {
        color: grey[900],
      },
    },
  },
  listItemActive: {
    fontWeight: 500,
    background: grey[100],
    '& $itemIcon': {
      color: grey[600],
    },
    '& $text': {
      color: grey[900],
    },
  },
  itemIcon: {
    color: grey['800'],
    fontSize: '23px',
  },
  text: {},
});

class UserProfile2Layout extends React.Component {
  static propTypes = {
    children: PropTypes.any,
  };

  render() {
    const { children, ...otherProps } = this.props;

    return (
      <ProfileContainer>
        <ProfileContent>
          {React.cloneElement(children, { ...otherProps })}
        </ProfileContent>
      </ProfileContainer>
    );
  }
}

function mapStateToProps(state) {
  const {
    user: { profileForm, profile },
  } = state;

  return { profileForm, profile };
}

export default connect(
  mapStateToProps,
  {}
)(withStyles(styles)(UserProfile2Layout));
